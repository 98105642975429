import ScrollToTop from "components/global/ScrollToTop";
import { useRoutes } from "react-router-dom";
import Router from "routes/Router";
import { ToastContainer } from 'react-toastify';


function App() {
    const routing = useRoutes(Router)

    return (
        <>
            <ScrollToTop>
                {routing}
            </ScrollToTop>
        </>
    );
}

export default App;
