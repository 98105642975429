import { lazy } from "react";
import Loadable from "components/global/Loadable";
import { Navigate } from "react-router-dom";


const MainLayout = Loadable(lazy(() => import('layouts/main/MainLayout')));
const BlankLayout = Loadable(lazy(() => import('layouts/blank/BlankLayout')));

const Home = Loadable(lazy(() => import('pages/home')));
const Contact = Loadable(lazy(() => import('pages/contact')));

// Services
const Tiktok = Loadable(lazy(() => import('pages/services/Tiktok')));
const AmazonPrivateLabel = Loadable(lazy(() => import("pages/services/AmazonPrivateLabel")))
const ShopifyPrivatelabel = Loadable(lazy(() => import("pages/services/ShopifyPrivateLabel")))
const ShippingServices = Loadable(lazy(() => import("pages/services/Shiping")))


const NotFound = Loadable(lazy(() => import('pages/404')))

const Router = [
    {
        path : '/' ,
        element : <MainLayout /> ,
        children: [
            { path: '/' , exact: true, element: <Home />  },
            { path: '/contact' , exact: true, element: <Contact />  },
            { path: '/services/tiktok' , exact: true, element: <Tiktok /> },
            { path: '/services/amazon-private-label' , exact: true, element: <AmazonPrivateLabel /> },
            { path: '/services/launch-shopify-private-label' , exact: true, element: <ShopifyPrivatelabel /> },
            { path: '/services/shipping' , exact: true, element: <ShippingServices /> },
            { path : '*' , element : <Navigate to='/page-not-found' /> }
        ]
    } , 
    {
        path : '/page-not-found' ,
        element : <BlankLayout /> ,
        children : [
            { path : '/page-not-found' , element : <NotFound /> }
        ]
    }
]

export default Router;