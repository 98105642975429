import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './App.css';
import './utilities.css';
import { BrowserRouter as Router } from "react-router-dom";
import Spinner from 'components/global/spinner/Spinner';

import "react-vertical-timeline-component/style.min.css";
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Suspense fallback={<Spinner />} >
        <Router>
            <App />
        </Router>
    </Suspense>
);


